<script lang="ts">
	let url = "https://www.coolenglish.edu.tw/voice-studio/";
</script>

<div class="flex h-full w-full items-center justify-center">
	<div>
		<p class="text-2xl">
			請透過 <a href={url} class="text-blue-700 underline decoration-solid">Cool English</a> 英語線上學習平台登入後使用
		</p>
	</div>
</div>
